<template>
  <div class="block-content clearfix matrix-bg bg-gray">
    <div class="pub-title">丰富的产品矩阵，提升管理效率</div>
    <div class="flex-content pub-mw m-auto" style="margin-top:40px">
      <div class="matrix-item flex-item wow"
        :class="{'fadeInLeft': inLeft.indexOf(index + 1) != -1, 'fadeInRight': inRight.indexOf(index + 1) != -1}"
        v-for="(item, index) in matrixData1" :key="index">
        <div class="home-matrix">
          <div class="home-matrix-icon">
            <img :src="item.iconUrl">
          </div>
          <div class="home-matrix-title f16">{{item.title}}</div>
          <div class="home-matrix-desc f13">{{item.desc}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      inLeft: [1, 2, 5, 6, 9, 10],
      inRight: [3, 4, 7, 8, 11, 12],
      matrixData1: [
        {
          iconUrl: require('@a/images/home/home_matrix01.png'),
          title: '会员管理',
          desc: '通过注册或授权的顾客进行有效管理，包含会员查询、消费记录、会员标签'
        },
        {
          iconUrl: require('@a/images/home/home_matrix02.png'),
          title: '商品管理',
          desc: '核心模块之一，给商品展示、订单、营销活动提供商品数据支撑'
        },
        {
          iconUrl: require('@a/images/home/home_matrix03.png'),
          title: '员工管理',
          desc: '涵盖了各个模块间的回路，涉及组织管理、员工管理、角色管理'
        },
        {
          iconUrl: require('@a/images/home/home_matrix04.png'),
          title: '营销管理',
          desc: '随业务变化调整，可通过多种工具，辅助企业提升效益'
        },
        {
          iconUrl: require('@a/images/home/home_matrix05.png'),
          title: '报表管理',
          desc: '会员报表、商品报表、库存报表、营销报表、设备报表、财务报表'
        },
        {
          iconUrl: require('@a/images/home/home_matrix06.png'),
          title: '设置管理',
          desc: '开店配置、支付配置、会员配置、广告管理、积分兑换配置'
        },
        {
          iconUrl: require('@a/images/home/home_matrix07.png'),
          title: '设备管理',
          desc: '各类型的设备管理、机台设备日志等'
        },
        {
          iconUrl: require('@a/images/home/home_matrix08.png'),
          title: '财务管理',
          desc: '包括订单管理、支付记录、财务对账'
        }
      ]
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
.matrix-bg {
  background-image: url("~@a/images/home/bgicon/icon_bg_01.png"),
    url("~@a/images/home/bgicon/icon_bg_02.png"),
    url("~@a/images/home/bgicon/icon_bg_03.png"),
    url("~@a/images/home/bgicon/icon_bg_04.png"),
    url("~@a/images/home/bgicon/icon_bg_05.png"),
    url("~@a/images/home/bgicon/icon_bg_06.png");
  background-position: left 40px top 140px, left 30% top 80px,
    left 60% top 145px, right top 50px, left 40px bottom 50px,
    right 20px bottom 5px;
  background-repeat: no-repeat;
}
// 矩阵样式
.matrix-item {
  float: left;
  margin: 0 5px;
}
.home-matrix {
  margin-top: 20px;
  width: 274px;
  height: 136px;
  padding: 30px 22px 0 22px;
  border-radius: 4px;
  background: #fff url("~@a/images/home/bgicon/card_bg.png") no-repeat right
    bottom;
  box-shadow: 0 0 5px #ccc;
  .home-matrix-icon {
    float: left;
    margin-top: 2px;
    width: 24px;
    height: 18px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .home-matrix-title {
    float: left;
    margin-left: 5px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
  .home-matrix-desc {
    float: left;
    margin-top: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.65);
  }
}
</style>
