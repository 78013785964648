<template>
  <div class="block-content bg-white">
    <div class="pub-title">融智盛维提供的相关专业服务</div>
    <div class="pub-title-small">科技想象力，游乐创造力</div>
    <el-tabs class="tabs-block-content" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="active1">
        <div slot="label" class="tabs-slot-title">
          <span></span>
          <p>收银台</p>
        </div>
        <div class="tabs-slot-content">
          <div class="tabs-flex-left">
            <div class="wow-tab slideInLeft" data-wow-delay="0s">
              <div class="title">收银台管理</div>
              <span
                class="desc">店面的休闲项目及服务性项目的收款、非会员游戏币的售卖、游戏币、游戏券套餐的售卖、会员信息的查询，具有绑卡、挂失、补卡、锁卡及查询店面的游戏币流水记录和彩票流水记录。</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.2s">
              <div class="title">兑奖台管理</div>
              <span class="desc">用于兑换台给顾客存取顾客在有游玩过程中产生的飞豆、用于飞豆兑换店面的礼品。</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.4s">
              <div class="title">库存管理</div>
              <span class="desc">店面库存的管理，统计各个入库记录及填写出入库单。</span>
            </div>
          </div>
          <div class="tabs-flex-rights wow-tab slideInRight" data-wow-delay="0.4s">
            <img src="@/assets/images/home/bitmap.png">
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="active2">
        <div slot="label" class="tabs-slot-title">
          <span></span>
          <p>一体机</p>
        </div>
        <div class="tabs-slot-content">
          <div class="tabs-flex-left">
            <div class="wow-tab slideInLeft" data-wow-delay="0s">
              <div class="title">会员管理</div>
              <span class="desc">会员可在一体机查看游戏币、游戏券、飞豆等会员资产</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.2s">
              <div class="title">套餐购买管理</div>
              <span class="desc">一体机支持游戏币、游戏券的套餐选择、支付、购买</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.4s">
              <div class="title">取币管理</div>
              <span class="desc">会员可通过刷会员卡和微信扫码在一体机进行取币；游客可在第三方购买游戏币或套餐，第三方渠道获取团购码时，可在一体机进行核销取币；</span>
            </div>
          </div>
          <div class="tabs-flex-rights wow-tab slideInRight" data-wow-delay="0.4s">
            <img src="@/assets/images/home/bitmap.png">
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="active3">
        <div slot="label" class="tabs-slot-title">
          <span></span>
          <p>刷卡器</p>
        </div>
        <div class="tabs-slot-content">
          <div class="tabs-flex-left">
            <div class="wow-tab slideInLeft" data-wow-delay="0s">
              <div class="title">扫码支付管理</div>
              <span class="desc">支持快闪店和外场扫码支付启动游戏机台</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.2s">
              <div class="title">刷卡投币管理</div>
              <span class="desc">用于门店内，刷卡支付启动游戏机台，颠覆传统游戏币经营模式，节省耗材及人力成本</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.4s">
              <div class="title">刷卡扣券管理</div>
              <span class="desc">用于门店游戏券活动、促销，提高机台整体使用率</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.6s">
              <div class="title">飞豆储存管理</div>
              <span class="desc">飞豆电子存储，节省耗材费用，便于用户和门店操作管理</span>
            </div>
          </div>
          <div class="tabs-flex-rights wow-tab slideInRight" data-wow-delay="0.6s">
            <img src="@/assets/images/home/bitmap.png">
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      activeName: 'active1'
    }
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: 'wow-tab',
        live: false
      }).init()
    })
  },
  methods: {
    handleClick (val) {
      let wowTab = document.querySelectorAll('.wow-tab')
      wowTab.forEach(item => {
        item.style.visibility = 'hidden'
      })
      new this.$wow.WOW({
        boxClass: 'wow-tab',
        live: false
      }).init()
    }
  }
}
</script>

<style lang="less">
.tabs-block-content {
  margin: 20px auto 0;
  width: 1150px;
  .el-tabs__header {
    margin: auto;
    width: 480px;
    text-align: center;
    .el-tabs__nav-scroll {
      .el-tabs__nav {
        margin-left: 60px;
      }
      .el-tabs__item {
        &:hover {
          .tabs-slot-title {
            span {
              border-color: #409eff;
            }
          }
        }
        &.is-active {
          .tabs-slot-title {
            span {
              border-color: #409eff;
            }
          }
        }
      }
      .tabs-slot-title {
        padding: 0 20px 0 15px;
        user-select: none;
        span {
          float: left;
          display: block;
          margin-top: 13px;
          width: 14px;
          height: 14px;
          border: solid 2px #333;
        }
        p {
          float: left;
          margin-left: 5px;
          font-size: 14px;
          color: 333;
        }
      }
    }
  }
  .tabs-slot-content {
    padding: 0 70px;
    .tabs-flex-left {
      float: left;
      width: 500px;
      .title {
        margin: 50px 0 14px;
        color: #404040;
        font-size: 13px;
      }
      .desc {
        color: #666;
        font-size: 12px;
      }
    }
    .tabs-flex-rights {
      float: right;
      width: 500px;
      img {
        margin-top: 50px;
        float: right;
        width: 384px;
      }
    }
  }
}
</style>
