<template>
  <div class="block-content bg-white">
    <div class="pub-title">刷卡器系统</div>
    <div class="pub-title-small">科技想象力，游乐创造力</div>
    <div class="flex-columns-content clearfix">
      <div class="flex-columns-box flex-left">
        <img class="wow slideInLeft" style="margin-top:20px" src="@a/images/product/pro-d-4.png">
      </div>
      <div class="flex-columns-box flex-right">
        <div class="wow slideInRight">
          <div class="title">扫码投币</div>
          <span class="desc">支持快闪店和外场扫码支付启动游戏机台</span>
        </div>
        <div class="wow slideInRight">
          <div class="title">刷卡投币</div>
          <span class="desc">用于门店内，刷卡支付启动游戏机台，颠覆传统游戏币经营模式，节省耗材及人力成本</span>
        </div>
        <div class="wow slideInRight">
          <div class="title">刷卡扣券</div>
          <span class="desc">用于门店游戏券活动、促销，提高机台整体使用率</span>
        </div>
        <div class="wow slideInRight">
          <div class="title">飞豆储存</div>
          <span class="desc">飞豆电子存储，节省耗材费用，便于用户和门店操作管理</span>
        </div>
        <div class="wow slideInRight go-detail-btn handle bg-blue" @click="toPath('trial')">了解详情
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      activeName: 'active1'
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClick () { }
  }
}
</script>

<style lang="less">
</style>
