<template>
  <div class="home-page">
    <server></server>
    <matrix></matrix>
    <swiper></swiper>
    <server-type></server-type>
    <checkwe></checkwe>
    <platform></platform>
  </div>
</template>

<script>
import server from './components/server'
import matrix from './components/matrix'
import swiper from './components/swiper'
import serverType from './components/serverType'
import platform from './components/platform'
import checkwe from './components/checkwe'
export default {
  name: 'home',
  props: [''],
  components: {
    server,
    matrix,
    swiper,
    serverType,
    platform,
    checkwe
  },
  data () {
    return {

    }
  },
  created () { },
  mounted () {
    this.$nextTick(() => {
      new this.$wow.WOW({
        live: false
      }).init()
    })
  },
  methods: {}
};
</script>

<style lang="less">
.home-page {
  .block-content {
    padding: 80px 0 60px;
  }
}
</style>
