<template>
  <div class="block-content bg-gray">
    <div class="flex-content m-auto clearfix">
      <div class="solution-item-icon right wow slideInRight">
        <img src="@a/images/solution/solu-item02.png">
      </div>
      <div class="solution-item-content left wow slideInLeft">
        <div class="title">02 O2O营销渠道 有效扩展新会员</div>
        <div class="divider"></div>
        <div class="desc-item">
          <div class="desc f14">美团、口碑等团购网站营销，线上购买套餐，拓宽客户来源</div>
          <div class="desc f14">微信公众号线上入会、充值、购买、兑换、抽奖等，解决场 地节假日服务瓶颈，提高顾客游乐体验</div>
        </div>
        <div class="advantage">
          <div class="advantage-item f16">丰富场地售卖渠道</div>
          <div class="advantage-item f16">提高顾客游乐体验</div>
          <div class="advantage-item f16">有效扩展新会员</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
</style>
