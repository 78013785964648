<template>
  <div class="block-content bg-white">
    <div class="pub-title">携手共赢，打造游乐行业一站式服务平台</div>
    <div class="platform-content clearfix m-auto">
      <div class="platform-item bg-plat_1 wow fadeInUp" data-wow-delay="0s"></div>
      <div class="platform-item bg-plat_2 wow fadeInUp" data-wow-delay="0.1"></div>
      <div class="platform-item bg-plat_3 wow fadeInUp" data-wow-delay="0.2s"></div>
      <div class="platform-item bg-plat_4 wow fadeInUp" data-wow-delay="0.3s"></div>
      <div class="platform-item bg-plat_5 wow fadeInUp" data-wow-delay="0.4s"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
.platform-content {
  margin-top: 60px;
  width: 1150px;
  .platform-item {
    float: left;
    width: 200px;
    height: 100px;
    &:not(:first-child) {
      margin-left: 37px;
    }
    &.bg-plat_1 {
      width: 200px;
      height: 100px;
      background: url("~@a/images/home/plat-bg-img.png") -5px -5px;
    }

    &.bg-plat_2 {
      width: 200px;
      height: 100px;
      background: url("~@a/images/home/plat-bg-img.png") -215px -5px;
    }

    &.bg-plat_3 {
      width: 200px;
      height: 100px;
      background: url("~@a/images/home/plat-bg-img.png") -425px -5px;
    }

    &.bg-plat_4 {
      width: 200px;
      height: 100px;
      background: url("~@a/images/home/plat-bg-img.png") -635px -5px;
    }

    &.bg-plat_5 {
      width: 200px;
      height: 100px;
      background: url("~@a/images/home/plat-bg-img.png") -845px -5px;
    }
  }
}
</style>
