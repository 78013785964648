<template>
  <div class="trial-content bg-blue clearfix">
    <p class="title">为运用乐园赋予全新的商业模式，创造运动与游戏的完美结合，为数千家游游乐场地赋能</p>
    <div class="trial-btn bg-white handle" @click="toPath('trial')">免费试用</div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="less">
.trial-content {
  height: 270px;
  text-align: center;
  font-size: 14px;
  background-image: url("~@a/images/home/bgicon/trial_bgicon01.png"),
    url("~@a/images/home/bgicon/trial_bgicon02.png"),
    url("~@a/images/home/bgicon/trial_bgicon03.png");
  background-position: left 190px top 130px, right 454px bottom 30px,
    right 200px top 60px;
  background-repeat: no-repeat;
  .title {
    margin: 85px auto 0;
    color: #fff;
  }
  .trial-btn {
    margin: 40px auto 0;
    width: 140px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    color: #2f54eb;
  }
}
</style>
