<template>
  <div class="block-content bg-white">
    <div class="pub-title">一体机系统</div>
    <div class="pub-title-small">科技想象力，游乐创造力</div>
    <div class="flex-columns-content clearfix">
      <div class="flex-columns-box flex-left">
        <div class="wow slideInLeft">
          <div class="title">会员管理</div>
          <span class="desc">会员可在一体机查看游戏币、游戏券、飞豆等会员资产</span>
        </div>
        <div class="wow slideInLeft">
          <div class="title">套餐购买管理</div>
          <span class="desc">一体机支持游戏币、游戏券的套餐选择、支付、购买</span>
        </div>
        <div class="wow slideInLeft">
          <div class="title">取币管理</div>
          <span class="desc">会员可通过刷会员卡和微信扫码在一体机进行取币；游客可在第三方购买游戏币或套餐，第三方渠道获取团购码时，可在一体机进行核销取币</span>
        </div>
        <div class="wow slideInLeft go-detail-btn handle bg-blue" @click="toPath('trial')">了解详情
        </div>
      </div>
      <div class="flex-columns-box flex-right">
        <img class="wow slideInRight" style="margin-top:20px" src="@a/images/product/pro-d-3.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      activeName: 'active1'
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClick () { }
  }
}
</script>

<style lang="less">
</style>
