<template>
  <div class="block-content bg-white">
    <div class="pub-title">产品介绍</div>
    <div class="pub-title-small">轻便型游乐场地管理软件，是一个通过线上线下+全员营销，实现会员、商品、订单、支付</div>
    <div class="pub-title-small" style="margin-top:2px">、营销、库存、供应链、资产、权限的管理</div>
    <div class="swiper-3d" id="swiper">
      <div class="swiper-slide" :class="list" v-for="(list, index) in classList" :key="index" @click="change(index)">
        <div class="swiper-img-url">
          <img :src="redEnveArr[index]">
        </div>
      </div>
      <div class="go-left-btn handle" @click="goRight"></div>
      <div class="go-right-btn handle" @click="goLeft"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      classList: ['slide-1', 'slide-2', 'slide-3'],
      redEnveArr: [require('@a/images/home/home_swiper01.png'), require('@a/images/home/home_swiper02.png'), require('@a/images/home/home_swiper03.png')]
    }
  },
  created () {

  },
  mounted () {
  },
  methods: {
    change (index) {
    },
    goRight () {
      this.classList.unshift(this.classList.pop())
    },
    goLeft () {
      this.classList.push(this.classList.shift())
    }
  }
}
</script>

<style lang="less">
@width: 502px;
@height: 372px;
// 层次元素的X轴偏移，缩放比例
.transX(@x) {
  @scale: (@x / 502);
  @averageX: ((@width - @x)/2);
}
// 层次元素的Y轴偏移
.transY(@y) {
  @averageY: ((@height - @y)/2);
}
.index(@num) {
  z-index: @num;
}
// 层次dom元素的便宜量，缩放比例
.transInfo(@diffX, @diffY) {
  transform: translate(@diffX - @averageX, @diffY - @averageY) scale(@scale);
}
.swiperStyle {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -@width / 2;
  box-sizing: border-box;
}

.swiper-3d {
  position: relative;
  margin: 65px auto 0;
  width: 1150px;
  height: 372px;
  transition: all 0.5s;
  .go-left-btn,
  .go-right-btn {
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    background: url("~@a/images/home/arrow-right.png") no-repeat center center;
    background-size: 100% 100%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .go-left-btn {
    left: 5px;
    transform: rotate(-180deg) translateY(50%);
  }
  .go-right-btn {
    right: 5px;
  }
  .swiper-slide {
    .swiperStyle;
    width: @width;
    height: @height;
    transform-origin: center center;
    transform: translate(0, 0) scale(1);
    transition: all 0.5s;
    .index(10);
    &.slide-1 {
      transform: translate(0, 0) scale(1);
    }
    &.slide-2 {
      .transX(448);
      .transY(332);
      .transInfo(-328, 22);
      .index(9);
    }
    &.slide-3 {
      .transX(448);
      .transY(332);
      .transInfo(382, 22);
      .index(8);
    }
  }
  .swiper-img-url {
    text-align: center;
    color: #fff;
    font-size: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
