<template>
  <div class="block-content bg-gray">
    <div class="pub-title">为什么选择融智盛维</div>
    <div class="home-checkwe m-auto">
      <div class="checkwe-main-box">
        <img class="checkwe-main-icon" src="@a/images/home/checkwe_bg.png">
        <div class="checkwe-pa-style checkwe-pa1 wow fadeInLeft" data-wow-delay="0s">
          <img class="checkwe-pa-icon" src="@a/images/home/checkwe_icon1.png">
          <div class="title">丰富的行业与实战经验</div>
          <div class="desc">对于门店数字化，线下线上流量裂变、会员精准营销、提升坪效等实际问题，有深刻理解和实战成功经验</div>
        </div>
        <div class="checkwe-pa-style checkwe-pa2 wow fadeInRight" data-wow-delay="0s">
          <img class="checkwe-pa-icon" src="@a/images/home/checkwe_icon2.png">
          <div class="title">全链路一体化解决方案</div>
          <div class="desc">完全覆盖客户运营环节的所有问题 线上线下会员权益、商品、进销存数据 打通、线上线下库存动态平衡等</div>
        </div>
        <div class="checkwe-pa-style checkwe-pa3 wow fadeInLeft" data-wow-delay="0.4s">
          <img class="checkwe-pa-icon" src="@a/images/home/checkwe_icon3.png">
          <div class="title">千店千面 基于商家个性需求定制</div>
          <div class="desc">突破千遍一律的定价、库存、销售和管理方式，制定差异化运营方案实质性提升消费体验</div>
        </div>
        <div class="checkwe-pa-style checkwe-pa4 wow fadeInRight" data-wow-delay="0.4s">
          <img class="checkwe-pa-icon" src="@a/images/home/checkwe_icon4.png">
          <div class="title">超高稳定与灵活的硬件系统</div>
          <div class="desc">服务器稳定性可达到99.99%，响应速度与并发量均处业内顶尖水平</div>
        </div>
      </div>
    </div>
    <div class="checkwe-tab">
      <div class="checkwe-tab-header">
        <div class="checkwe-tab-item handle" :class="{'active': active == '0'}" @click="changeTab('0')">协助客户提升</div>
        <div class="checkwe-tab-item handle" :class="{'active': active == '1'}" @click="changeTab('1')">降低成本统计</div>
      </div>
      <div class="checkwe-tab-content clearfix" v-show="active === '0'">
        <div class="checkwe-tab-content-item fl fadeInUp" data-wow-delay="0s">
          <div class="number">+50%</div>
          <div class="title">活跃会员</div>
        </div>
        <div class="checkwe-tab-content-item fc fadeInUp" data-wow-delay="0.2s">
          <div class="number">+30%</div>
          <div class="title">线上订单</div>
        </div>
        <div class="checkwe-tab-content-item fr fadeInUp" data-wow-delay="0.4s">
          <div class="number">+50%</div>
          <div class="title">复购率</div>
        </div>
      </div>
      <div class="checkwe-tab-content clearfix" v-show="active === '1'">
        <div class="checkwe-tab-content-item fl fadeInUp" data-wow-delay="0s">
          <div class="number">-40%</div>
          <div class="title">支付费率</div>
        </div>
        <div class="checkwe-tab-content-item fc fadeInUp" data-wow-delay="0.2s">
          <div class="number">-30%</div>
          <div class="title">运营成本</div>
        </div>
        <div class="checkwe-tab-content-item fr fadeInUp" data-wow-delay="0.4s">
          <div class="number">-50%</div>
          <div class="title">损耗</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      active: '0'
    }
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: 'checkwe-tab-content-item',
        live: false
      }).init()
    })
  },
  methods: {
    changeTab (type) {
      this.active = type
      let wowTab = document.querySelectorAll('.checkwe-tab-content-item')
      wowTab.forEach(item => {
        item.style.visibility = 'hidden'
      })
      new this.$wow.WOW({
        boxClass: 'checkwe-tab-content-item',
        live: false
      }).init()
    }
  }
}
</script>

<style lang="less">
.home-checkwe {
  margin-top: 110px;
  .checkwe-main-box {
    margin: auto;
    width: 610px;
    position: relative;
  }
  .checkwe-main-icon {
    width: 100%;
    object-fit: contain;
  }
  .checkwe-pa-style {
    position: absolute;
    width: 150px;
    &.checkwe-pa1 {
      left: -170px;
      top: -70px;
    }
    &.checkwe-pa2 {
      right: -170px;
      top: -70px;
    }
    &.checkwe-pa3 {
      left: -170px;
      bottom: 50px;
    }
    &.checkwe-pa4 {
      right: -170px;
      bottom: 50px;
    }
    .checkwe-pa-icon {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
    .title {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #404040;
      line-height: 20px;
    }
    .desc {
      margin-top: 15px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
  }
}
.checkwe-tab {
  margin: 50px auto 0;
  width: 1050px;
  .checkwe-tab-header {
    text-align: center;
    .checkwe-tab-item {
      position: relative;
      display: inline-block;
      width: 170px;
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      text-align: center;
      &.active {
        color: #5f9bf1;
        &:before {
          content: "";
          position: absolute;
          height: 6px;
          width: 40px;
          background: #5f9bf1;
          bottom: -14px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .checkwe-tab-content {
    padding-top: 62px;
    text-align: center;
    .checkwe-tab-content-item {
      display: inline-block;
      text-align: center;
      &.fl {
        float: left;
      }
      &.fr {
        float: right;
      }
      .number {
        font-size: 64px;
        font-weight: bold;
        color: #5f9bf1;
        line-height: 74px;
      }
      .title {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
    }
  }
}
</style>
