<template>
  <div class="pub-swiper">
    <el-carousel class="h-swiper" trigger="click" :interval="5000" arrow="never" @change="changePubSwiper">
      <el-carousel-item>
        <div class="h-swiper-desc left">
          <p class="s-t1 vi-hidden fadeInLeft" :class="{'wow-h-swiper':carouselIndex == 0}">游戏体验业态会员运营专家</p>
          <p class="s-t2 vi-hidden fadeInLeft" :class="{'wow-h-swiper':carouselIndex == 0}" data-wow-delay="0.3s">助力游乐行业
            游乐营销宝典</p>
          <div class="btn-detail handle vi-hidden fadeInUp" :class="{'wow-h-swiper':carouselIndex == 0}"
            data-wow-delay="0.5s" @click="toPath('trial')">
            了解详情</div>
        </div>
        <img src="@a/images/home/banner1.png" class="banner-logo logo1 right vi-hidden wow-h-swiper fadeInRight"
          data-wow-delay="0.3s">
      </el-carousel-item>
      <el-carousel-item>
        <div class="h-swiper-desc left">
          <p class="s-t1 vi-hidden fadeInLeft" :class="{'wow-h-swiper':carouselIndex == 1}">基于线上线下的数字能力</p>
          <p class="s-t2 vi-hidden fadeInLeft" :class="{'wow-h-swiper':carouselIndex == 1}" data-wow-delay="0.3s">
            满足游乐行业+多业态的打通愿景</p>
          <div class="btn-detail handle vi-hidden fadeInUp" :class="{'wow-h-swiper':carouselIndex == 1}"
            data-wow-delay="0.5s" @click="toPath('trial')">
            了解详情</div>
        </div>
        <img src="@a/images/home/banner2.png" class="banner-logo logo2 right vi-hidden wow-h-swiper fadeInRight"
          data-wow-delay="0.3s">
      </el-carousel-item>
      <el-carousel-item>
        <div class="h-swiper-desc left">
          <p class="s-t1 vi-hidden fadeInLeft" :class="{'wow-h-swiper':carouselIndex == 2}">致力成为游乐行业被信任的合作伙伴
          </p>
          <p class="s-t2 vi-hidden fadeInLeft" :class="{'wow-h-swiper':carouselIndex == 2}" data-wow-delay="0.3s">实战 高效
            懂商户
          </p>
          <div class="btn-detail handle vi-hidden fadeInUp" :class="{'wow-h-swiper':carouselIndex == 2}"
            data-wow-delay="0.5s" @click="toPath('trial')">
            了解详情</div>
        </div>
        <img src="@a/images/home/banner3.png" class="banner-logo logo3 right vi-hidden wow-h-swiper fadeInRight"
          data-wow-delay="0.3s">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      carouselIndex: 0
    }
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: 'wow-h-swiper',
        live: false
      }).init()
    })
  },
  methods: {
    changePubSwiper (index) {
      this.carouselIndex = index
      var hiden = document.querySelectorAll('.vi-hidden')
      hiden.forEach(item => {
        item.style.visibility = 'hidden'
      })
      setTimeout(() => {
        new this.$wow.WOW({
          boxClass: 'wow-h-swiper',
          live: false
        }).init()
      }, 300)

    }
  }
}
</script>

<style lang="less">
.pub-swiper {
  padding-top: 80px;
  height: 580px;
  background: #17141e;
  .h-swiper {
    height: 100%;
    .el-carousel__container {
      height: inherit;
      margin: auto;
      max-width: 1150px;
    }
    .el-carousel__indicators {
      bottom: 60px !important;
      .el-carousel__indicator {
        .el-carousel__button {
          margin: 0 5px;
          padding: 0;
          width: 14px;
          height: 14px;
          background: #fff;
          border-radius: 50%;
        }
      }
    }

    .banner-logo {
      position: absolute;
      right: 0;
      top: 80px;
      width: 480px;
      &.logo1 {
        width: 480px;
        height: 318px;
      }
      &.logo2 {
        top: 20px;
        margin-right: 100px;
        width: 360px;
        height: 424px;
      }
      &.logo3 {
        top: 50px;
        margin-right: 50px;
        width: 350px;
        height: 340px;
      }
    }
    .h-swiper-desc {
      margin-top: 120px;
      p {
        color: #fff;
        &.s-t1 {
          font-size: 40px;
        }
        &.s-t2 {
          margin-top: 10px;
          font-size: 26px;
        }
      }
      .btn-detail {
        margin-top: 60px;
        width: 110px;
        height: 40px;
        border-radius: 6px;
        color: #fff;
        background: #2f54eb;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
      }
    }
  }
}
</style>
