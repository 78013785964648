<template>
  <div class="contact-box">
    <div class="about-pub-title">技术支持</div>
    <div class="contact-flex-box clearfix">
      <div class="contact-flex-item left">
        <div class="qq clearfix">
          <img src="@a/images/about/contact_icon1.png">
          <span>值班QQ: 800054844</span>
        </div>
        <div class="time">9:00~24:00随时响应客服服务</div>
      </div>
      <div class="contact-flex-item right">
        <div class="qq clearfix">
          <img src="@a/images/about/contact_icon2.png">
          <span>售后服务: 4006880835</span>
        </div>
        <div class="time">9:00~24:00随时响应客服服务</div>
      </div>
    </div>
    <div class="about-pub-title" style="margin-top:60px">联系我们</div>
    <div class="address-box">
      <p>联系地址：湖北省武汉市江夏区未来科技城C1栋10楼</p>
      <p>联系电话：027-59006604</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
.contact-box {
  .contact-flex-box {
    margin-top: 60px;
    .contact-flex-item {
      width: 430px;
      height: 141px;
      padding-left: 40px;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      .qq {
        margin-top: 25px;
        color: #404040;
        font-size: 20px;
        font-weight: 600;
        img {
          margin-top: -5px;
          display: inline-block;
          width: 36px;
          height: 36px;
          vertical-align: middle;
        }
        span {
          display: inline-block;
          margin-left: 16px;
          line-height: 36px;
        }
      }
      .time {
        margin-top: 20px;
        font-size: 20px;
        color: #666666;
        line-height: 28px;
      }
    }
  }
  .address-box {
    margin-top: 60px;
    p {
      font-size: 16px;
      font-weight: 400;
      color: #404040;
      line-height: 22px;
      margin-bottom: 24px;
    }
  }
}
</style>
