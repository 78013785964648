<template>
  <div class="solution-box">
    <item1></item1>
    <item2></item2>
    <item3></item3>
    <item4></item4>
    <item5></item5>
    <item6></item6>
    <item7></item7>
  </div>
</template>

<script>
import item1 from './components/item1'
import item2 from './components/item2'
import item3 from './components/item3'
import item4 from './components/item4'
import item5 from './components/item5'
import item6 from './components/item6'
import item7 from './components/item7'
export default {
  name: '',
  props: [''],
  components: {
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item7
  },
  data () {
    return {
    }
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      new this.$wow.WOW({
        live: false
      }).init()
    })
  },
  methods: {

  }
}
</script>

<style lang="less">
.solution-box {
  .block-content {
    padding: 80px 0 60px;
    .flex-content {
      max-width: 1150px;
    }
  }
  .flex-content {
    padding: 40px 0 60px;
  }
  .solution-item-icon {
    width: 550px;
    height: 316px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .solution-item-content {
    width: 516px;
    height: auto;
    .title {
      color: #404040;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
    }
    .divider {
      position: relative;
      margin-top: 10px;
      width: 100%;
      height: 1px;
      background: #e9e9e9;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        width: 25px;
        background: #404040;
      }
    }
    .desc-item {
      margin-top: 32px;
      .desc {
        margin-left: 15px;
        display: list-item;
        list-style: decimal;
        line-height: 20px;
        color: #666;
        padding: 0;
      }
    }
    .advantage {
      margin-top: 24px;
      .advantage-item {
        margin-top: 8px;
        padding-left: 25px;
        height: 20px;
        line-height: 20px;
        background: url(~@a/images/right.png) no-repeat left center;
        background-size: 20px 20px;
        color: #333;
      }
    }
  }
}
</style>
