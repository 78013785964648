<template>
  <div class="example-box">
    <div class="block-content bg-gray">
      <div class="pub-title">客户案例</div>
      <div class="flex-content m-auto clearfix">
        <div class="example-item-box">
          <div class="example-item wow fadeInUp">
            <img src="@a/images/example/example_list01.png">
            <span>助力大玩家新店开业</span>
          </div>
          <div class="example-item ml wow fadeInUp">
            <img src="@a/images/example/example_list02.png">
            <span>单个项目营收日均10000+</span>
          </div>
          <div class="example-item wow fadeInUp">
            <img src="@a/images/example/example_list03.png">
            <span>2年拓展400+门店</span>
          </div>
          <div class="example-item ml wow fadeInUp">
            <img src="@a/images/example/example_list04.png">
            <span>单店月营收30万+</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      new this.$wow.WOW({
        live: false
      }).init()
    })
  },
  methods: {

  }
}
</script>

<style lang="less">
.example-box {
  .block-content {
    padding: 80px 0 60px;
    background-image: url("~@a/images/home/bgicon/icon_bg_01.png"),
      url("~@a/images/home/bgicon/icon_bg_04.png"),
      url("~@a/images/home/bgicon/icon_bg_07.png"),
      url("~@a/images/home/bgicon/icon_bg_06.png");
    background-position: left 60px top 80px, right top 36px,
      left 300px top 570px, right 120px top 560px;
    background-repeat: no-repeat;
    .flex-content {
      max-width: 1150px;
      .example-item-box {
        margin-top: 60px;
        .example-item {
          float: left;
          margin-bottom: 30px;
          width: 565px;
          height: 410px;
          background: #ffffff;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
          &.ml {
            margin-left: 19px;
          }
          img {
            width: 100%;
            height: 319px;
            object-fit: cover;
            overflow: hidden;
          }
          span {
            display: block;
            height: 90px;
            line-height: 90px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
