<template>
  <div class="block-content bg-white">
    <div class="pub-title">产品与服务</div>
    <div class="flex-content pub-mw m-auto clearfix">
      <div class="server-item flex-item wow fadeInUp" :data-wow-delay="index * 0.1 + 's'"
        v-for="(item, index) in proServer" :key="index">
        <div class="home-product-server">
          <div class="home-item-icon">
            <img :src="item.iconUrl">
          </div>
          <div class="home-item-title">{{item.title}}</div>
          <div class="home-item-desc">{{item.desc}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      proServer: [
        {
          iconUrl: require('@a/images/home/home_server01.png'),
          title: 'GMS系统',
          desc: '游乐+多业态、乐园全场一卡通、会员营销'
        },
        {
          iconUrl: require('@a/images/home/home_server02.png'),
          title: '收银POS系统',
          desc: '支付、结算、核算接口产品效率翻四倍'
        },
        {
          iconUrl: require('@a/images/home/home_server03.png'),
          title: '一体机系统',
          desc: '会员管理、套餐购买管理、取币管理'
        },
        {
          iconUrl: require('@a/images/home/home_server04.png'),
          title: '刷卡器系统',
          desc: '扫码支付管理、刷币投币管理、飞豆储存管理'
        },
        {
          iconUrl: require('@a/images/home/home_server05.png'),
          title: '小程序',
          desc: '会员及资产查看、积分兑换、购币商城、兑换商城、礼品商城、线上小游戏'
        },
        {
          iconUrl: require('@a/images/home/home_server06.png'),
          title: '各系统运维服务',
          desc: '病毒防护、数据存储、周期服务、维护系统平台安全'
        }
      ]
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
// 产品与服务样式
.server-item {
  float: left;
  width: 33.33%;
}
.home-product-server {
  margin: 60px auto 0;
  width: 230px;
  text-align: center;
  .home-item-icon {
    margin: auto;
    width: 100px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .home-item-title {
    margin-top: 10px;
    font-size: 20px;
    line-height: 28px;
    color: #404040;
  }
  .home-item-desc {
    margin-top: 5px;
    font-size: 16px;
    color: #404040;
    line-height: 20px;
  }
}
</style>
