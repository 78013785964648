<template>
  <div class="block-content bg-gray">
    <div class="pub-title">电玩娱乐一站式解决方案</div>
    <div class="flex-content m-auto clearfix">
      <div class="solution-item1-box">
        <img class="item1-icon" src="@a/images/solution/solution-main.png">
        <div class="item1-content pos1">
          <div class="title">多门店管控 全方位监测</div>
          <p class="desc">覆盖会员、商品、营销于一体，轻松管理店面</p>
        </div>
        <div class="item1-content pos2">
          <div class="title">移动化管理门店</div>
          <p class="desc">随时随地掌握门店信息</p>
        </div>
        <div class="item1-content pos3">
          <div class="title">灵活售卖方式 支持多种营销模式</div>
          <p class="desc">支持多种储值方式 多业态一卡通管理</p>
        </div>
        <div class="item1-content pos4">
          <div class="title">全方位数据化财务报表分析</div>
          <p class="desc">清晰完整的财务数据化报表 掌握经营情况 及时调整</p>
        </div>
        <div class="item1-content pos5">
          <div class="title">自有渠道+线上第三方分销</div>
          <p class="desc">丰富场地售卖渠道，灵活玩转线上线下</p>
        </div>
        <div class="item1-content pos6">
          <div class="title">小程序+线上兑换商城</div>
          <p class="desc">礼品兑换更轻简 彩票积分当钱花</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
.solution-item1-box {
  position: relative;
  margin-top: 80px;
  .item1-icon {
    margin: auto;
    display: block;
    width: 622px;
    height: 554px;
  }
  .item1-content {
    position: absolute;
    width: 208px;
    .title {
      font-size: 20px;
      line-height: 1.4;
      color: #404040;
    }
    .desc {
      margin-top: 5px;
      font-size: 14px;
      line-height: 20px;
      color: #666;
    }
    &.pos1 {
      left: 120px;
      top: 6px;
    }
    &.pos2 {
      right: 70px;
      top: 6px;
    }
    &.pos3 {
      left: 40px;
      top: 180px;
    }
    &.pos4 {
      right: 30px;
      top: 210px;
    }
    &.pos5 {
      left: 80px;
      bottom: 20px;
    }
    &.pos6 {
      right: 120px;
      bottom: 50px;
    }
  }
}
</style>
