<template>
  <div class="block-content case-bg-box bg-gray">
    <div class="pub-title">客户案例</div>
    <div class="flex-content m-auto clearfix">
      <div class="case-box clearfix">
        <el-carousel trigger="click" :interval="3000" arrow="never">
          <el-carousel-item>
            <div class="case-box-item">
              <img class="left" src="@a/images/solution/case/case-list01.png">
              <div class="case-box-content right">
                <div class="title">大玩家-室内新游乐典范</div>
                <div class="desc">
                  致力于通过对线下娱乐场景的精细化运营与创新，为积极乐观、热爱生活的消费者提供极致的快乐体验。<br>大玩家在传统电玩的基础上，创造性的融合了室内嘉年华、电子竞技、棒球、射箭、保龄球等运动休闲项目，以及具有领先科技元素的炫酷影吧、VR体验馆等，引领了中国室内新游乐时代。
                </div>
              </div>
            </div>
          </el-carousel-item>
          <!-- <el-carousel-item>
            <div class="case-box-item">
              <img src="@a/images/solution/case/case-list01.png">
              <div class="case-box-content">
                <div class="title">大玩家-室内新游乐典范</div>
                <div class="desc">
                  致力于通过对线下娱乐场景的精细化运营与创新，为积极乐观、热爱生活的消费者提供极致的快乐体验。<br>大玩家在传统电玩的基础上，创造性的融合了室内嘉年华、电子竞技、棒球、射箭、保龄球等运动休闲项目，以及具有领先科技元素的炫酷影吧、VR体验馆等，引领了中国室内新游乐时代。
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="case-box-item">
              <img src="@a/images/solution/case/case-list01.png">
              <div class="case-box-content">
                <div class="title">大玩家-室内新游乐典范</div>
                <div class="desc">
                  致力于通过对线下娱乐场景的精细化运营与创新，为积极乐观、热爱生活的消费者提供极致的快乐体验。<br>大玩家在传统电玩的基础上，创造性的融合了室内嘉年华、电子竞技、棒球、射箭、保龄球等运动休闲项目，以及具有领先科技元素的炫酷影吧、VR体验馆等，引领了中国室内新游乐时代。
                </div>
              </div>
            </div>
          </el-carousel-item> -->
        </el-carousel>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
.case-bg-box {
  background-image: url("~@a/images/home/bgicon/icon_bg_01.png"),
    url("~@a/images/home/bgicon/icon_bg_04.png"),
    url("~@a/images/home/bgicon/icon_bg_07.png"),
    url("~@a/images/home/bgicon/icon_bg_06.png");
  background-position: left 60px top 80px, right top 36px,
    left 300px bottom 70px, right 120px bottom 10px;
  background-repeat: no-repeat;
}
.case-box {
  margin: auto;
  width: 1008px;
  height: 393px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  .el-carousel,
  .el-carousel__container {
    height: 100% !important;
  }
  .el-carousel {
    .el-carousel__indicators {
      display: none;
      bottom: 10px;
      .el-carousel__button {
        background: #2f54eb;
      }
    }
  }
  .case-box-item {
    padding: 56px;
    img {
      width: 500px;
      height: 280px;
      object-fit: contain;
    }
    .case-box-content {
      width: 340px;
      .title {
        height: 32px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
      }
      .desc {
        margin-top: 28px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
      }
    }
  }
}
</style>
