<template>
  <div class="process-box">
    <div class="about-pub-title">发展历程</div>
    <div class="process-content">
      <div class="process-content-item clearfix process">
        <div class="year-title">2019</div>
        <div class="item-desc f16">
          2019年公司产品系统重构，升级至第三代系统<br>由基于门店独立服务器的系统平台升级至基于云服务的SAAS系统平台，其中主要核心业务包括GMS运营管理平台、新版BOSS收银台、店务通APP等</div>
      </div>
      <div class="process-content-item clearfix process">
        <div class="year-title">2018</div>
        <div class="item-desc f16">
          2018年公司正式入驻未来科技城<br>规划开展游戏、经营管理系统软硬件开发、系统集成、大数据和人工智能技术服务等业务
        </div>
      </div>
      <div class="process-content-item clearfix">
        <div class="year-title">2017</div>
        <div class="item-desc f16">2017年武汉融智盛维科技发展有限公司在武汉东湖新技术开发区注册成功<br>致力于室内游乐、游艺产业链的转型升级</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
.process-box {
  .process-content {
    margin-top: 60px;
    position: relative;
    .process-content-item {
      position: relative;
      padding-bottom: 60px;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        width: 8px;
        height: 8px;
        background: #2f54eb;
        border: solid 4px #d5dcfb;
        border-radius: 50%;
        z-index: 10;
      }
      &.process:after {
        content: "";
        position: absolute;
        left: 7px;
        top: 6px;
        width: 2px;
        height: 100%;
        background: #e9e9e9;
        z-index: 8;
      }
      .year-title {
        // flex: 1;
        float: left;
        padding-left: 32px;
        font-size: 20px;
        color: #404040;
        line-height: 28px;
      }
      .item-desc {
        float: right;
        width: 788px;
        color: #666;
        line-height: 32px;
      }
    }
  }
}
</style>
