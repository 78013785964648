<template>
  <div class="honor-box">
    <div class="about-pub-title">荣誉与资质</div>
    <div class="honor-flex-box clearfix">
      <div class="honor-flex-item left">
        <img class="honor-logo" src="@a/images/about/honor-icon1.png">
        <div class="title">质量管理体系认证证书</div>
        <div class="desc">武汉融智盛维科技发展有限公司质量管理体系符合GB/t19001-2016 idt ISO9001:2016标准<br>使用条款的要求（包含GMS智慧管理系统软件、智能自助机、刷卡器的开发）
        </div>
      </div>
      <div class="honor-flex-item left">
        <img class="honor-logo" src="@a/images/about/honor-icon2.png">
        <div class="title">管理体系认证证书</div>
        <div class="desc">
          武汉融智盛维科技发展有限公司信息安全管理体系符合GB/T22080-2016/ISO/IEC27001:2013<br>本证书覆盖范围位于湖北省武汉市东湖新技术开发区未来科技城C1座1002室从事与GMS智慧超乐场管理系统开发及运维相关的信息安全管理活动
        </div>
      </div>
    </div>
    <div class="honor-flex-box clearfix" style="margin-top: 100px">
      <div class="honor-flex-item left">
        <img class="honor-logo" src="@a/images/about/honor-icon3.png">
        <div class="title">软件产品证书</div>
        <div class="desc">经评估，武汉融智盛维科技GMS智慧超乐场管理系统V1.0质符合《进一步鼓励软件产业和集成电路产业发展的若干政策》和《软件产品评估标准》(T/SIA003 2017)的有关规定，评估为软件产品。
        </div>
      </div>
      <div class="honor-flex-item left">
        <img class="honor-logo" src="@a/images/about/honor-icon4.png">
        <div class="title">信用等级证书</div>
        <div class="desc">联合信用评价有限公司湖北分公司通过对武汉融智盛维科技发展有限公司的信用状态进行综合分析和评估，确定
          武汉融智盛维科技发展有限公司信用等级为AA-</div>
      </div>
    </div>
    <div class="process-content">
      <div class="process-content-item process">
        <div class="year-title">2019年11月获得高新技术企业证书</div>
      </div>
      <div class="process-content-item process">
        <div class="year-title">2019年10月获得东湖新技术开发区颁布的“瞪羚企业”称号</div>
      </div>
      <div class="process-content-item">
        <div class="year-title">2019年5月获得湖北省软件行业协会颁布的“会员单位”</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
.honor-box {
  .honor-flex-box {
    margin-top: 60px;
    .honor-flex-item {
      position: relative;
      width: 50%;
      height: 290px;
      background: #fff;
      border: 1px solid #e2e2e2;
      &:last-of-type {
        border-left: none;
      }
      .honor-logo {
        position: absolute;
        left: 50%;
        top: -42px;
        transform: translateX(-50%);
        width: 112px;
        height: 112px;
      }
      .title {
        margin-top: 80px;
        color: #404040;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }
      .desc {
        margin-top: 28px;
        padding: 0 34px;
        font-size: 16px;
        color: #666;
        line-height: 22px;
        text-align: center;
      }
    }
  }
  .process-content {
    margin-top: 60px;
    position: relative;
    .process-content-item {
      position: relative;
      padding-bottom: 60px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        width: 8px;
        height: 8px;
        background: #2f54eb;
        border: solid 4px #d5dcfb;
        border-radius: 50%;
        z-index: 10;
      }
      &.process:after {
        content: "";
        position: absolute;
        left: 7px;
        top: 6px;
        width: 2px;
        height: 100%;
        background: #e9e9e9;
        z-index: 8;
      }
      .year-title {
        flex: 1;
        padding-left: 32px;
        font-size: 20px;
        color: #404040;
        line-height: 28px;
      }
    }
  }
}
</style>
