const devUploadUrl = {
  uploadUrl: 'http://10.201.5.142:8080/file/upload/file',
  uploadImg: 'http://10.201.5.142:8080/file/upload/img'
}
const urlEnums = {
  dev: { // 开发联调环境
    baseUrl: 'https://apitest.kiscloud.net',
    ...devUploadUrl
  },
  rc: { // rc环境
    baseUrl: 'https://apirc.kiscloud.net',
    ...devUploadUrl
  },
  rc3: { // rc3环境
    baseUrl: 'https://apirc3.kiscloud.net',
    ...devUploadUrl
  },
  rc4: { // rc4环境
    baseUrl: 'http://apinew.rc4.kiscloud.net',
    ...devUploadUrl
  },
  gray: { // rc4环境
    baseUrl: 'http://apigray.kiscloud.net',
    ...devUploadUrl
  },
  prod: { // 线上生产环境
    baseUrl: 'https://api.kiscloud.net',
    uploadUrl: 'http://ty-api.kiscloud.net/file/upload/file',
    uploadImg: 'http://ty-api.kiscloud.net/file/upload/img'
  }
}
export default {
  ...urlEnums[process.env.NODE_BUILD],
  title: '武汉融智',
  cookieExpires: 1,
  homeName: 'index'
}
