import axios from 'axios'
import config from '@/config'
import qs from 'qs'

// 创建axios实例
const service = axios.create({
  baseURL: config.baseUrl,
  timeout: 20000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(config => {
  if (!config.params) {
    config.params = {}
  }
  if ((config.method + '').toLocaleLowerCase() == 'post') {
    if (config.params.needStringify) {
      delete config.params.needStringify
      config.data = qs.stringify(config.params)
    } else {
      config.data = config.params
    }
    config.params = {}
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})
// respone拦截器
service.interceptors.response.use(
  response => {
    let getResData = response.config.getResData
    return returnData(response, getResData)
  },
  error => {
    if (!window.navigator.onLine) {
      error.message = '网络异常，请检查网络设置'
    }
    return Promise.resolve({
      status: 'ERROR',
      error: error.message
    })
  }
)

function returnData (response, isResponse) {
  return isResponse ? response : (response.data || {})
}

export default service
