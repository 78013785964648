import fetch from '../utils/fetch'
export default {
  /**
   * {提交}
   */
  submit (params) {
    return fetch({
      url: 'oms/merchant/mall/apply/employ/save',
      method: 'post',
      params
    })
  },
  /**
   * {获取验证码}
   */
  getCode (params) {
    return fetch({
      url: '/oms/merchant/mall/apply/employ/send/msg',
      method: 'post',
      params
    })
  }
}