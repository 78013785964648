<template>
  <div class="page-footer">
    <div class="footer-box m-auto">
      <div class="grid-box clearfix">
        <div class="grid-item">
          <img class="logo" src="@a/images/logo.png">
          <div class="logo-desc">A efficient motion design solutions</div>
        </div>
        <div class="grid-item">
          <div class="grid-title">产品</div>
          <div class="grid-list">
            <div class="grid-list-item">
              <span >GMS商户运营管理系统</span>
            </div>
            <div class="grid-list-item ">
              <span >慧联商户运营管理系统</span>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-title">关于</div>
          <div class="grid-list">
            <div class="grid-list-item">
              <span class="link handle" @click="toPath('introduce')">企业简介</span>
            </div>
            <div class="grid-list-item">
              <span class="link handle" @click="toPath('contact')">联系我们</span>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-title">联系我们</div>
          <div class="grid-list">
            <div class="grid-list-item">售后服务：400-188-9580</div>
            <div class="grid-list-item">值班QQ：800054844</div>
          </div>
        </div>
        <!-- <div class="grid-item">
          <div class="grid-title">关注</div>
          <img class="qrcode" src="@a/images/mini-logo.png">
        </div> -->
      </div>
      <div class="copyright-box"><a target="_blank" style="color:#cccccc;" href="https://beian.miit.gov.cn/">鄂ICP备18031153号</a><br>Copyright © 2018 武汉融智盛维科技发展有限公司. All Rights Reserved</div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {
    goGms () {
      window.open('http://static.kiscloud.net/#/home')
    },
    goPos () {
      window.open('https://oss.kiscloud.net/boss/gms.exe')
    }
  }
}
</script>

<style lang="less">
.page-footer {
  // height: 390px;
  background: #17141e;
  .footer-box {
    width: 1150px;
    height: 100%;
    .grid-box {
      border-bottom: solid 1px rgba(204, 204, 204, 0.35);
    }
    .grid-item {
      float: left;
      padding: 62px 0;
      // width: 209px;
      width: 265px;
      &:first-of-type {
        // width: 312px;
        width: 352px;
      }
      .logo {
        width: 156px;
      }
      .logo-desc {
        margin-top: 10px;
        font-size: 12px;
        color: #999999;
        line-height: 14px;
      }
      .grid-title {
        font-size: 18px;
        font-weight: 400;
        color: #d8d8d8;
        line-height: 25px;
      }
      .qrcode {
        margin-top: 22px;
        width: 120px;
        height: 120px;
      }
      .grid-list {
        margin-top: 16px;
        .grid-list-item {
          font-size: 12px;
          font-weight: 400;
          color: #cccccc;
          line-height: 24px;
          .link:hover {
            color: #2f54eb;
          }
        }
      }
    }
    .copyright-box {
      text-align: center;
      padding: 30px 0;
      line-height: 1.8;
      font-size: 12px;
      color: #cccccc;
      span {
        color: #2f54eb;
      }
    }
  }
}
</style>
