<template>
  <div class="header-bar clearfix">
    <div class="bar-bg"></div>
    <div class="pub-header-content m-auto">
      <div class="logo">
        <img src="@a/images/logo.png">
      </div>
      <div class="pub-menu">
        <router-link class="pub-menu-nav" tag="div" to="/pc/home">首页</router-link>
        <router-link class="pub-menu-nav" tag="div" to="/pc/product">产品中心</router-link>
        <router-link class="pub-menu-nav" tag="div" to="/pc/solution">解决方案</router-link>
        <router-link class="pub-menu-nav" tag="div" to="/pc/example">客户案例</router-link>
        <router-link class="pub-menu-nav" tag="div" to="/about">关于我们</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {
    // window.addEventListener('scroll', this.scrollPage)
  },
  methods: {
    scrollPage () {
      var distanceSroll = 600
      if (window.scrollY > distanceSroll) {
        document.querySelector('.bar-bg').style = 'opacity:1'
      } else {
        document.querySelector('.bar-bg').style = 'opacity:' + (window.scrollY - 10) / (distanceSroll - 10)
      }
    }
  },
  destroyed () {
    // window.removeEventListener('scroll', this.scrollPage)
  }
}
</script>

<style lang="less">
.header-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  // background: #f00;
  background: #17141e;

  z-index: 999;
  height: 80px;
  .bar-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 2;
  }
  .pub-header-content {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1150px;
    height: 100%;
    color: #2f54eb;
    z-index: 10;
  }
  .logo {
    float: left;
    margin-top: 20px;
    height: 40px;
    * {
      height: 100%;
    }
  }
  .pub-menu {
    float: right;
    user-select: none;
    .pub-menu-nav {
      float: left;
      position: relative;
      margin: 20px 25px;
      padding: 10px 0;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      transition: all 0.5s;
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 50%;
        background: #2f54eb;
        transform: translateX(-50%);
      }
      &.router-link-active {
        color: #2f54eb;
        &:after {
          width: 94%;
          transition: width 0.5s;
        }
      }
    }
  }
}
</style>
