<template>
  <div class="pro-page">
    <gms></gms>
    <serveritem1></serveritem1>
    <serveritem2></serveritem2>
    <serveritem3></serveritem3>
    <serveritem4></serveritem4>
    <serveritem5></serveritem5>
  </div>
</template>

<script>
import gms from './components/gms'
import serveritem1 from './components/server-item1'
import serveritem2 from './components/server-item2'
import serveritem3 from './components/server-item3'
import serveritem4 from './components/server-item4'
import serveritem5 from './components/server-item5'
export default {
  name: 'product',
  props: [''],
  components: {
    gms,
    serveritem1,
    serveritem2,
    serveritem3,
    serveritem4,
    serveritem5
  },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      new this.$wow.WOW({
        live: false
      }).init()
    })
  },
  methods: {

  }
}
</script>

<style lang="less">
.pro-page {
  .block-content {
    padding: 80px 0 60px;
  }
  .flex-columns-content {
    margin: auto;
    padding: 0 50px;
    width: 1150px;
    .flex-columns-box {
      .title {
        margin: 50px 0 14px;
        color: #404040;
        font-size: 13px;
      }
      .desc {
        color: #666;
        font-size: 12px;
      }
      .go-detail-btn {
        margin-top: 40px;
        width: 110px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 6px;
        color: #fff;
        font-size: 14px;
      }
    }
    .flex-left {
      float: left;
      width: 50%;
      img {
        float: left;
        width: 90%;
      }
    }
    .flex-right {
      float: right;
      padding-left: 52px;
      width: 50%;
      img {
        float: right;
        width: 90%;
      }
    }
  }
}
</style>
