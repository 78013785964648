<template>
  <div class="block-content bg-white">
    <div class="flex-content m-auto clearfix">
      <div class="solution-item-icon left wow slideInLeft">
        <img src="@a/images/solution/solu-item01.png">
      </div>
      <div class="solution-item-content right wow slideInRight">
        <div class="title">01 “降缓节”三部曲 有效节省场地开支</div>
        <div class="divider"></div>
        <div class="desc-item">
          <div class="desc f14">彩票、实物币系统盘点，改变传统人工盘点工作量大的问题</div>
          <div class="desc f14">一体机购币减少收银台排队问题</div>
          <div class="desc f14">自助设备为场地排队分流</div>
          <div class="desc f14">库存盘点提升场地使用率</div>
          <div class="desc f14">自助礼品兑换，减少场地库存压力</div>
          <div class="desc f14">代币的应用减少实物币损耗现象</div>
        </div>
        <div class="advantage">
          <div class="advantage-item f16">减低人工成本</div>
          <div class="advantage-item f16">缓解库存压力</div>
          <div class="advantage-item f16">节省物资损耗</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
</style>
