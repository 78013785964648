<template>
  <div class="block-content bg-white">
    <div class="pub-title">小程序</div>
    <div class="pub-title-small">科技想象力，游乐创造力</div>
    <div class="flex-columns-content clearfix">
      <div class="flex-columns-box flex-left">
        <div class="wow slideInLeft">
          <div class="title">会员管理</div>
          <span class="desc">会员个人信息及资产信息展示</span>
        </div>
        <div class="wow slideInLeft">
          <div class="title">积分兑换</div>
          <span class="desc">会员积分兑换商品，包含兑换规则和对应积分扣减</span>
        </div>
        <div class="wow slideInLeft">
          <div class="title">订单管理</div>
          <span class="desc">核心模块之一，订单的下单流程涵盖了各个模块间的回路，涉及订单的拆单、订单售后、线下服务订单等。</span>
        </div>
        <div class="wow slideInLeft">
          <div class="title">支付管理</div>
          <span class="desc">支付服务商、支付通道、商户账户、门店支付、支付记录、退款记录等。系统对接招商银行pos机</span>
        </div>
        <div class="wow slideInLeft handle go-detail-btn bg-blue" @click="toPath('trial')">了解详情
        </div>
      </div>
      <div class="flex-columns-box flex-right">
        <img class="wow slideInRight" style="margin-top:50px" src="@a/images/product/pro-d-5.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      activeName: 'active1'
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
  }
}
</script>

<style lang="less">
</style>
