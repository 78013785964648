<template>
  <div class="block-content bg-white">
    <div class="pub-title">各系统运维服务</div>
    <div class="pub-title-small">科技想象力，游乐创造力</div>
    <div class="flex-columns-content clearfix">
      <div class="flex-columns-box flex-left">
        <img class="wow slideInLeft" style="margin-top:40px" src="@a/images/product/pro-d-6.png">
      </div>
      <div class="flex-columns-box flex-right">
        <div class="wow slideInRight">
          <div class="title">服务周期</div>
          <span class="desc">全天24小时随时响应客服服务</span>
        </div>
        <div class="wow slideInRight">
          <div class="title">服务器网络宽带类别</div>
          <span class="desc">阿里云平台独享服务器，享有网络带宽200兆及以上，满足业务高峰期需要。</span>
        </div>
        <div class="wow slideInRight">
          <div class="title">数据存储</div>
          <span class="desc">双核存储内存，以保证对数据通信的安全、交换、访问进行可控制策略，以及安全隐患进行全面的跟踪筛查存储备份。</span>
        </div>
        <div class="wow slideInRight">
          <div class="title">病毒防护</div>
          <span class="desc">制定病毒防护和回复策略，定期评估病毒影响，采取相应的病毒防护措施和制定病毒事件处理预案。</span>
        </div>
        <div class="wow slideInRight go-detail-btn handle bg-blue" @click="toPath('trial')">了解详情
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      activeName: 'active1'
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClick () { }
  }
}
</script>

<style lang="less">
</style>
