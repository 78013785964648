<template>
  <div class="block-content bg-white">
    <div class="flex-content m-auto clearfix">
      <div class="solution-item-icon left wow slideInLeft">
        <img src="@a/images/solution/solu-item03.png">
      </div>
      <div class="solution-item-content right wow slideInRight">
        <div class="title">03 总部多门店管控</div>
        <div class="divider"></div>
        <div class="desc-item">
          <div class="desc f14">通过远程设置直接操作各门店的运营管理，解决连锁多店散、乱、杂的问题，且覆盖门店的人事、加盟、营销、采购、物流、财务、信息管理等各项工作的监控
          </div>
        </div>
        <div class="advantage">
          <div class="advantage-item f16">解决门店扩张管理难题</div>
          <div class="advantage-item f16">实现跨地域、跨平台、跨公网的一体化管理</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
</style>
