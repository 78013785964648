<template>
  <div class="intro-box">
    <div class="about-pub-title">公司简介</div>
    <img class="intro-logo" src="@a/images/about/intro-icon.png">
    <p class="desc">武汉融智盛维科技发展有限公司是一家专注于信息技术服务的创新型技术企业。</p>
    <p class="desc">
      公司成立于2017年9月28日，注册资金5000万，目前公司有近100位员工，大部分是资深的互联网、软件研发人员，拥有完善的自主研发体系，在游艺娱乐的硬件平台、软件研发、技术转移和服务等方向都拥有了丰富的研究成果，与多家知名联盟商户、用户建立了合作关系，在信息化与自动化领域拥有极强的商业模式策划能力、自主技术研发能力及运维能力。
    </p>
    <p class="desc">
      融智盛维致力于室内游乐、游艺产业链的转型升级，通过不断跟踪行业内的新型技术特点，创造了行业内优质的软硬件技术，建立了完善的管理体系。公司主要产品为门店+多业态管理SAAS平台，已在国内多家知名联盟商户使用，帮助泛娱乐行业商户整合数据资源，打造数字资源库，提供基于用户数据分析技术，开展个性化定向投送模块建设；提供多业态的门店管理系统，建设商户人员、消费者、boss数据管理系统，开展生产与消费互动的定制化服务模式探索，形成线上与线下(O2O)互动的内容投送新模式，取得了非常好的效果。
    </p>
    <p class="desc">
      公司秉持用科技赋予超乐场智慧的先进理念，力争打造以技术、服务、产品为载体的国内游艺、游乐场所综合解决方案第一品牌。公司将始终把客户放在第一位，通过不断提高公司产品的科技含量和技术服务水平，为新老客户提供先进的、高质量的、应用广泛的行业整体解决方案和信息技术服务。
    </p>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
.intro-box {
  .intro-logo {
    margin-top: 40px;
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .desc {
    margin-top: 32px;
    font-size: 16px;
    line-height: 32px;
    color: #666;
  }
}
</style>
