<template>
  <div class="block-content bg-gray">
    <div class="flex-content m-auto clearfix">
      <div class="solution-item-icon right wow slideInRight">
        <img src="@a/images/solution/solu-item04.png">
      </div>
      <div class="solution-item-content left wow slideInLeft">
        <div class="title">04 礼品兑换更轻简</div>
        <div class="divider"></div>
        <div class="desc-item">
          <div class="desc f14">线上兑换礼品，无需线下排队，不仅提升兑换体验、解决节假日现场兑换服务瓶 颈，还能提高门店面积利用率</div>
          <div class="desc f14">一站式好礼自助兑换，为场地提升客流赋能的同时有效为兑换区分流，让顾客把 彩票、积分当钱花</div>
        </div>
        <div class="advantage">
          <div class="advantage-item f16">提高门店面积利用率</div>
          <div class="advantage-item f16">提升兑换体验</div>
          <div class="advantage-item f16">解决节假日现场兑换服务瓶颈</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
</style>
