<template>
  <div class="trial-page bg-gray clearfix">
    <div class="header clearfix">
      <!-- <div class="bar-bg"></div> -->
      <div class="pub-header-content m-auto">
        <div class="logo">
          <img src="@a/images/logo.png">
        </div>
        <div class="pub-menu">
          <router-link class="pub-menu-nav" tag="div" to="/pc/home">返回首页</router-link>
        </div>
      </div>
    </div>
    <div class="form-bg-box">
      <div class="form-box">
        <el-form label-position="right" label-width="80px" :model="formTrial" :rules="rules" ref="formTrial">
          <el-form-item label="姓名" prop="userName">
            <el-input v-model="formTrial.userName" minlength="2" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="公司名称" prop="orgName">
            <el-input v-model="formTrial.orgName" maxlength="200"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model="formTrial.phone" maxlength="11" @input="ketupsort"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input v-model="formTrial.code" maxlength="6" style="width:317px"></el-input>
            <el-button plain style="margin-left:20px; width:112px;padding:12px 0;text-aligen:center"
              :disabled="disabled || formTrial.phone.length != 11" @click="getCode">
              {{btnText}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="submit-btn" type="primary" @click="submitForm('formTrial')" size="small">提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import API from '@/api'
import { checkPhone } from '@/utils/validate'
import pageFooter from '@c/footer/footer'
export default {
  name: '',
  props: [''],
  components: { pageFooter },
  data () {
    return {
      formTrial: {
        userName: '',
        orgName: '',
        phone: '',
        code: ''
      },
      btnText: '获取验证码',
      count: 60,
      disabled: false,
      rules: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
        ],
        orgName: { required: true, message: '请输入公司名称', trigger: 'blur' },
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        code: { required: true, message: '验证码不能为空', trigger: 'blur' }
      }
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    ketupsort (val) {
      this.formTrial.phone = val.replace(/[^\d]/g, '')
    },
    // 获取验证码
    async getCode () {
      if (this.formTrial.phone === '') {
        return
      }
      if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.formTrial.phone)) {
        return
      }
      let res = await API.getCode({
        merchantId: 'dwj',
        applyPhone: this.formTrial.phone
      })
      if (res.status === 200) {
        this.disabled = true
        this.btnText = '发送成功(' + this.count + ')'
        this.count--
        this.timer = setInterval(() => {
          this.btnText = '发送成功(' + this.count + ')'
          this.count--
          if (this.count === -1) {
            this.disabled = false
            this.count = 60
            this.btnText = '获取验证码'
            clearInterval(this.timer)
          }
        }, 1000)
      } else {
        this.$message.error(res.error)
      }
    },
    async submit () {
      let res = await API.submit({
        merchantId: "dwj",
        applyPhone: this.formTrial.phone,
        applyName: this.formTrial.userName,
        companyName: this.formTrial.orgName,
        valiCode: this.formTrial.code
      })
      if (res.status === 200) {
        this.$message.success('申请成功，请静候客服人员联系您')
        setTimeout(() => {
          this.$router.push({ name: 'home' })
        }, 2000)
      } else {
        this.$message.error(res.error)
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.trial-page {
  .form-bg-box {
    padding: 64px 0 120px 0;
    background-image: url("~@a/images/home/bgicon/icon_bg_01.png"),
      url("~@a/images/home/bgicon/icon_bg_02.png"),
      url("~@a/images/home/bgicon/icon_bg_03.png"),
      url("~@a/images/home/bgicon/icon_bg_05.png"),
      url("~@a/images/home/bgicon/icon_bg_06.png");
    background-position: left 50px top 160px, left 400px top 18px,
      right 334px top 48px, left 40px bottom 50px, right -36px bottom -36px;
    background-repeat: no-repeat;
  }
  .form-box {
    margin: auto;
    padding: 60px 310px 0 310px;
    width: 1150px;
    height: 470px;
    background: #fff;
  }
  .header {
    background: #17141e;
    height: 80px;
    .pub-header-content {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 1150px;
      height: 100%;
      color: #2f54eb;
    }
    .logo {
      float: left;
      margin-top: 20px;
      height: 40px;
      * {
        height: 100%;
      }
    }
    .pub-menu {
      float: right;
      user-select: none;
      .pub-menu-nav {
        position: relative;
        margin: 20px 25px;
        padding: 10px 0;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        transition: all 0.5s;
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px;
          bottom: 0;
          left: 50%;
          background: #2f54eb;
          transform: translateX(-50%);
        }
        &.router-link-active {
          color: #2f54eb;
          &:after {
            width: 94%;
            transition: width 0.5s;
          }
        }
      }
    }
  }
  .submit-btn {
    background: #2f54eb;
    padding: 10px 22px;
    outline: none;
    border: none;
  }
}
</style>
