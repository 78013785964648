<template>
  <div class="rzsw-page">
    <header-bar></header-bar>
    <fixedBtn></fixedBtn>
    <pub-swiper></pub-swiper>
    <router-view />
    <trial></trial>
    <page-footer></page-footer>
  </div>
</template>

<script>
// import headerBar from '../../components/header/header-bar'
import headerBar from '@c/header/header-bar'
import fixedBtn from '@c/fixedBtn/btn'
import pubSwiper from '@c/header-swiper/swiper'
import trial from '@c/trial/trial'
import pageFooter from '@c/footer/footer'
export default {
  name: 'solution',
  props: [''],
  components: {
    headerBar,
    fixedBtn,
    pubSwiper,
    trial,
    pageFooter
  },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
</style>
