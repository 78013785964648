<template>
  <div class="block-content bg-white">
    <div class="flex-content m-auto clearfix">
      <div class="solution-item-icon left wow slideInLeft">
        <img src="@a/images/solution/solu-item05.png">
      </div>
      <div class="solution-item-content right wow slideInRight">
        <div class="title">05 全方位报表分析</div>
        <div class="divider"></div>
        <div class="desc-item">
          <div class="desc f14">会员报表，掌握消费习惯</div>
          <div class="desc f14">财务报表，掌握账目盈亏、售卖明细、资金流向，赌防营收漏洞</div>
          <div class="desc f14">掌握经营情况，及时调整，提高场地营收</div>
        </div>
        <div class="advantage">
          <div class="advantage-item f16">提高复购率</div>
          <div class="advantage-item f16">掌握资金情况，提高场地营收</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
</style>
