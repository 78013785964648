<template>
  <div class="block-content bg-white">
    <div class="pub-title-large">用全方位解决方案和优质服务满足您的需求</div>
    <div class="pub-title" style="margin-top:70px">GMS系统</div>
    <div class="pub-title-small">科技想象力，游乐创造力</div>
    <el-tabs class="tabs-block-content" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="active1">
        <div slot="label" class="tabs-slot-title">
          <span></span>
          <p>功能一</p>
        </div>
        <div class="tabs-slot-contents clearfix">
          <div class="tabs-flex-left">
            <div class="wow-tab slideInLeft" data-wow-delay="0s">
              <div class="title">设备管理</div>
              <span class="desc">用于设置及添加游乐项目设备，包括机台管理、机台查询、机台维修、机台调拨、场地机台设置等。</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.2s">
              <div class="title">会员管理</div>
              <span class="desc">会员级别设置、会员权益设置、会员储值等会员管理设置及会员的相关查询项</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.4s">
              <div class="title">商品管理</div>
              <span class="desc">设置场地商品资料、库存及仓储管理、售卖及兑换设置、套票管理、导购设置及查询</span>
            </div>
            <div class="go-detail-btn handle bg-blue wow-tab slideInLeft" data-wow-delay="0.6s"
              @click="toPath('trial')">了解详情</div>
          </div>
          <div class="tabs-flex-right">
            <img class="wow-tab slideInRight" data-wow-delay="0.2s" src="@a/images/product/pro-d-1.png">
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="active2">
        <div slot="label" class="tabs-slot-title">
          <span></span>
          <p>功能二</p>
        </div>
        <div class="tabs-slot-contents">
          <div class="tabs-flex-left">
            <div class="wow-tab slideInLeft" data-wow-delay="0s">
              <div class="title">营销管理</div>
              <span class="desc">满赠促销、满减促销、折扣促销、限购促销、兑换折扣、活动赠送及小程序新人礼包等营销方案设置，同时支持查询相关营销方案使用情况</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.2s">
              <div class="title">财务管理</div>
              <span class="desc">财务内外部对账、支付方式设置、及相关财务管理设置统计。并支持查询相关财务数据查询。</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.4s">
              <div class="title">报表管理</div>
              <span class="desc">商品销售报表及分析，会员消费及活跃度分析、游乐项目时段报表及人流统计、财务营收情况报表及手机报表设置。</span>
            </div>
            <div class="go-detail-btn handle bg-blue wow-tab slideInLeft" data-wow-delay="0.6s"
              @click="toPath('trial')">了解详情</div>
          </div>
          <div class="tabs-flex-right">
            <img class="wow-tab slideInRight" data-wow-delay="0.2s" src="@a/images/product/pro-d-1.png">
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="active3">
        <div slot="label" class="tabs-slot-title">
          <span></span>
          <p>功能三</p>
        </div>
        <div class="tabs-slot-contents">
          <div class="tabs-flex-left">
            <div class="wow-tab slideInLeft" data-wow-delay="0s">
              <div class="title">设置管理</div>
              <span class="desc">开店设置、收银终端设置、会员储值规则等会员设置、套票相关规则设置、广告投放设置及小程序设置等功能。</span>
            </div>
            <div class="wow-tab slideInLeft" data-wow-delay="0.2s">
              <div class="title">员工管理</div>
              <span class="desc">门店、员工权限角色设置、操作日志查询等</span>
            </div>
            <div class="go-detail-btn handle bg-blue wow-tab slideInLeft" data-wow-delay="0.4s"
              @click="toPath('trial')">了解详情</div>
          </div>
          <div class="tabs-flex-right">
            <img class="wow-tab slideInRight" data-wow-delay="0.2s" src="@a/images/product/pro-d-1.png">
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      activeName: 'active1'
    }
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: 'wow-tab',
        live: false
      }).init()
    })
  },
  methods: {
    handleClick (val) {
      let wowTab = document.querySelectorAll('.wow-tab')
      wowTab.forEach(item => {
        item.style.visibility = 'hidden'
      })
      new this.$wow.WOW({
        boxClass: 'wow-tab',
        live: false
      }).init()
    }
  }
}
</script>

<style lang="less">
.tabs-block-content {
  margin: 20px auto 0;
  width: 1150px;
  .el-tabs__header {
    margin: auto;
    width: 480px;
    text-align: center;
    .el-tabs__nav-scroll {
      .el-tabs__nav {
        margin-left: 60px;
      }
      .el-tabs__item {
        &:hover {
          .tabs-slot-title {
            span {
              border-color: #409eff;
            }
          }
        }
        &.is-active {
          .tabs-slot-title {
            span {
              border-color: #409eff;
            }
          }
        }
      }
      .tabs-slot-title {
        padding: 0 20px 0 15px;
        user-select: none;
        span {
          float: left;
          display: block;
          margin-top: 13px;
          width: 14px;
          height: 14px;
          border: solid 2px #333;
        }
        p {
          float: left;
          margin-left: 5px;
          font-size: 14px;
          color: 333;
        }
      }
    }
  }
  .tabs-slot-contents {
    padding: 0 50px;
    .tabs-flex-left {
      float: left;
      width: 500px;
      padding-top: 40px;
      .title {
        margin: 34px 0 5px;
        color: #404040;
        font-size: 13px;
      }
      .desc {
        color: #666;
        font-size: 12px;
      }
      .go-detail-btn {
        margin-top: 40px;
        width: 110px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 6px;
        color: #fff;
        font-size: 14px;
      }
    }
    .tabs-flex-right {
      float: right;
      width: 500px;
      img {
        margin-top: 40px;
        float: right;
        width: 400px;
      }
    }
  }
}
</style>
