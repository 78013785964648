<template>
  <div class="culture-box">
    <div class="about-pub-title">企业文化</div>
    <div class="cul-title">五心文化</div>
    <div class="cul-logo-box">
      <img class="culture-logo" src="@a/images/about/culture-icon.png">
      <div class="cul-fixed-item fixed1">
        <div class="cul-item-title">忠心</div>
        <div class="cul-item-desc">事业、公司、岗位</div>
      </div>
      <div class="cul-fixed-item fixed2">
        <div class="cul-item-title">恒心</div>
        <div class="cul-item-desc">持之以恒、坚持不懈</div>
      </div>
      <div class="cul-fixed-item fixed3">
        <div class="cul-item-title">热心</div>
        <div class="cul-item-desc">全情投入、不断思索</div>
      </div>
      <div class="cul-fixed-item fixed4">
        <div class="cul-item-title">爱心</div>
        <div class="cul-item-desc">快乐、团结、有温度</div>
      </div>
      <div class="cul-fixed-item fixed5" style="width:100px">
        <div class="cul-item-title">感恩<br>之心</div>
        <div class="cul-item-desc">感恩、回馈</div>
      </div>
    </div>
    <div class="desc f16">公司倡导“五心文化”；忠心：事业、公司、岗位；恒心：持之以恒、坚持不懈；热心：全情投入、不断思索；爱心：快乐、团结、有温度；感恩之心：感恩、回馈；</div>
    <div class="about-pub-title" style="margin-top:60px">企业精神</div>
    <div class="culture-item-flex clearfix">
      <div class="culture-item left">
        <img src="@a/images/about/culture-icon1.png">
        <span>坚韧不拔</span>
      </div>
      <div class="culture-item right">
        <img src="@a/images/about/culture-icon2.png">
        <span>凝聚包容</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
.culture-box {
  .cul-title {
    margin-top: 40px;
    font-size: 20px;
    font-weight: 600;
    color: #404040;
    line-height: 28px;
    text-align: center;
  }
  .cul-logo-box {
    margin: 20px auto 0;
    width: 544px;
    height: 282px;
    position: relative;
    .culture-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .cul-fixed-item {
      position: absolute;
      text-align: center;
      top: 0;
      .cul-item-title {
        font-size: 20px;
        font-weight: 500;
        color: #404040;
        line-height: 28px;
      }
      .cul-item-desc {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
      &.fixed1 {
        left: -110px;
        top: 85px;
      }
      &.fixed2 {
        left: 25px;
        top: 240px;
      }
      &.fixed3 {
        left: 202px;
        top: 300px;
      }
      &.fixed4 {
        left: 400px;
        top: 240px;
      }
      &.fixed5 {
        left: 520px;
        top: 75px;
      }
    }
  }
  .desc {
    margin-top: 136px;
    color: #666;
  }
  .culture-item-flex {
    margin-top: 60px;
    .culture-item {
      width: 430px;
      height: 114px;
      line-height: 114px;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      text-align: center;
      img {
        margin-top: -5px;
        display: inline-block;
        width: 50px;
        height: 50px;
        object-fit: contain;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        margin-left: 20px;
        font-size: 20px;
        font-weight: 600;
        color: #404040;
      }
    }
  }
}
</style>
