<template>
  <div class="about-page clearfix">
    <header-bar></header-bar>
    <fixedBtn></fixedBtn>
    <div class="about-banner">
      <div class="banner-box m-auto clearfix">
        <div class="content">
          <div class="title">关于我们</div>
          <div class="desc">发现和创造超乎用户期待的娱乐产品、场景和体验</div>
        </div>
      </div>
    </div>
    <div class="router-view-box m-auto clearfix">
      <div class="left-menu left">
        <router-link class="nav-item f16 handle" tag="div" to="/about/introduce" @click.native="linkClick">公司简介
        </router-link>
        <router-link class="nav-item f16 handle" tag="div" to="/about/culture" @click.native="linkClick">企业文化
        </router-link>
        <router-link class="nav-item f16 handle" tag="div" to="/about/process" @click.native="linkClick">发展历程
        </router-link>
        <router-link class="nav-item f16 handle" tag="div" to="/about/honor" @click.native="linkClick">荣誉与资质
        </router-link>
        <router-link class="nav-item f16 handle" tag="div" to="/about/contact" @click.native="linkClick">联系我们
        </router-link>
      </div>
      <div class="right-content right">
        <router-view></router-view>
      </div>
    </div>
    <trial></trial>
    <page-footer></page-footer>
  </div>
</template>

<script>
import fixedBtn from '@c/fixedBtn/btn'
import headerBar from '@c/header/header-bar'
import trial from '@c/trial/trial'
import pageFooter from '@c/footer/footer'
export default {
  name: 'about',
  props: [''],
  components: {
    headerBar, trial, pageFooter, fixedBtn
  },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    linkClick () {
      window.scrollTo(0, 500)
    }
  }

}
</script>

<style lang="less">
.about-page {
  .about-banner {
    width: 100%;
    height: 580px;
    background: #17141e;
    .banner-box {
      max-width: 1150px;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      background: url("~@a/images/about/about-bg.png") no-repeat right bottom
        50px;
      background-size: 550px auto;
      .content {
        margin-top: 220px;
        color: #fff;
        .title {
          font-size: 48px;
          line-height: 68px;
        }
        .desc {
          margin-top: 20px;
          font-size: 28px;
          line-height: 40px;
        }
      }
    }
  }

  .router-view-box {
    margin: 64px auto;
    width: 1150px;
    .left-menu {
      width: 200px;
      height: auto;
      border-right: solid 1px #e9e9e9;
      .nav-item {
        position: relative;
        text-indent: 40px;
        width: 188px;
        height: 60px;
        line-height: 60px;
        border-bottom: solid 1px #e9e9e9;
        color: #404040;
        &:before {
          content: "";
          position: absolute;
          left: 12px;
          top: 49%;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #999;
          transform: translateY(-50%);
        }
        &.router-link-active {
          color: #2f54eb;
          &:before {
            background: #2f54eb;
          }
        }
      }
    }
    .right-content {
      width: 888px;
      .about-pub-title {
        font-size: 24px;
        font-weight: 600;
        color: #404040;
        line-height: 32px;
      }
    }
  }
}
</style>
