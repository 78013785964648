<template>
  <div class="block-content bg-white">
    <div class="pub-title">收银pos系统</div>
    <div class="pub-title-small">科技想象力，游乐创造力</div>
    <div class="flex-columns-content clearfix">
      <div class="flex-columns-box flex-left">
        <img class="wow slideInLeft" style="margin-top:10px" src="@a/images/product/pro-d-2.png">
      </div>
      <div class="flex-columns-box flex-right">
        <div class="wow slideInRight">
          <div class="title">收银台管理</div>
          <span
            class="desc">店面的休闲项目及服务性项目的收款、非会员游戏币的售卖、游戏币、游戏券套餐的售卖、会员信息的查询，具有绑卡、挂失、补卡、锁卡、及查询会员流水信息、查询店面的游戏币流水记录和彩票流水记录</span>
        </div>
        <div class="wow slideInRight">
          <div class="title">兑奖台管理</div>
          <span class="desc">用于兑换台给顾客存取顾客在游玩过程中产生的飞豆、用于飞豆兑换店面的礼品</span>
        </div>
        <div class="wow slideInRight">
          <div class="title">库存管理</div>
          <span class="desc">店面库存的管理，统计各出入库记录，及填写出入库单</span>
        </div>
        <div class="wow slideInRight go-detail-btn handle bg-blue" @click="toPath('trial')">了解详情
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: [''],
  components: {},
  data () {
    return {
      activeName: 'active1'
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClick () { }
  }
}
</script>

<style lang="less">
</style>
